<template>
    <div>
  <!-- Start -->
  <section class="relative md:py-24 py-16 md:pt-0 pt-0 bg-gray-50 dark:bg-slate-800" id="features">
            <div class="container">
                <div class="grid grid-cols-1 justify-center">
                    <div class="relative z-1">
                        <div class="grid lg:grid-cols-12 grid-cols-1 md:text-start text-center justify-center">
                            <div class="lg:col-start-2 lg:col-span-10">
                                <div class="relative">
                                   <iframe src="https://www.google.com/maps/d/u/0/embed?mid=1QwNPLlkYD0hteR7ZsewffwAykmxzprU&ehbc=2E312F" width="100%" height="530"></iframe>
                                </div>
                            </div>
                        </div>
                        <div class="content md:mt-8">
                            <div class="text-center justify-center">                                
                                <div class="section-title">
                                    <h3 class="text-white/50">¡No te lo pierdas!</h3>
                                    <h5 class="md:text-2xl text-xl font-medium text-white mt-2">                                         
                                        Ubica los museos de Yucatán y planea tu visita</h5>
                                </div>                                       
                            </div>
                        </div>
                    </div>
                </div>
                <!--end row -->
            </div>
            <!--end container-->

            <div class="absolute bottom-0 end-0 start-0 h-4/5 md:h-2/3 bg-violet-600"></div>
        </section>
        <!-- End -->
        
    </div>
</template>

<script>
    export default {
        name: "ctaVue",
    }
</script>

<style lang="scss" scoped>

</style>