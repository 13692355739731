<template>
 <!-- <HelloWorld /> -->
  <router-view></router-view>
  <!-- <Footer /> -->
  <Switcher />
</template>

<script>
  import feather from 'feather-icons'
// import Footer from './components/Footer.vue';
import Switcher from './components/Switcher.vue'

// import HelloWorld from './components/HelloWorld.vue'

export default {
  name: 'App',
  components: {
    // Footer,
    Switcher
},
  mounted() {
      feather.replace();
  },
}
</script>

<style>



</style>
