<template>
    <div>
    <!-- Start -->
    <section class="relative md:py-24 py-16" id="blog">
            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-4 md:text-2xl text-xl font-medium">¿No sabes qué museos visitar?</h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto">Conoce lo museos más emblemáticos y algunos que quizás no conocías, donde cada pieza cuenta una historia fascinante y cada sala es una ventana a 
                        nuestro pasado y presente.</p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 lg:grid-cols-3 md:grid-cols-2 gap-[30px] mt-8">
                    <div v-for="item in BlogData" :key="item" class="blog relative rounded-md shadow shadow-slate-200 dark:shadow-gray-800 overflow-hidden">
                        <router-link :to="{ name: 'Blog-Detail', params: { id: item.id } }">
                            <img :src="getImageUrl(item.media)" alt="">
                        </router-link>

                        <div class="content p-6">
                            <router-link :to="{ name: 'Blog-Detail', params: { id: item.id } }"  class="text-lg hover:text-violet-600 dark:text-white dark:hover:text-violet-600 transition duration-500 ease-in-out font-medium h5">{{ item.nombre_institucion }}</router-link>
                            <p class="text-slate-400 mt-3">{{ shortenDescription(item.descripcion) }}</p>                           
                        </div>
                    </div>

                </div><!--end grid-->

                <div class="text-center mt-8">
                   <router-link to="/museos"> <a href=""
                        class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md"><i
                        class="uil uil-eye"></i>Todos los museos</a>
                   </router-link>
                </div>

            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
    </div>
</template>

<script>
import { apiClient } from '../services/index';

    export default {
        name:"BlogsVue",
        data() {
            return {
                BlogData: []
            }            
        },
        created() {
            this.fetchMuseums();
        },
        methods: {
            async fetchMuseums() {
                try {
                    const response = await apiClient.get('/home');                
                    this.BlogData = response.data;
                    console.log(response);
                } catch (error) {
                    console.error('Error fetching museums:', error);
                }
            },
            getImageUrl(media) {
                if (media && media.length > 0) {
                    return media[0].original_url;
                } else {
                    return ''; // Otra ruta o un placeholder en caso de que no haya una imagen
                }
            },
            shortenDescription(description) {
                if (description && description.length > 150) {
                    return description.substring(0, 150) + '...';
                } else {
                    return description;
                }
            }
        }
    }
</script>

<style lang="scss" scoped>

</style>