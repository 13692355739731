<template>
  <div class="hello">
    <NavbarVue />
    <!-- Hero Start -->
    <section class="py-36 lg:py-56 w-full table relative bg-[url('../../assets/images/bg/museo-cancion.jpg')] bg-top bg-no-repeat"
      id="home">
      <div class="absolute inset-0 bg-gradient-to-t to-slate-950/50 via-slate-950/75 from-slate-950"></div>

      <div class="container">
        <div class="grid grid-cols-1 pb-8 text-center mt-10">
          <a href="#"  @click="startVideo" data-type="youtube" data-id="yba7hPeTSjk"
            class="lightbox lg:size-20 size-16 rounded-full shadow-lg dark:shadow-gray-800 inline-flex items-center justify-center bg-white hover:bg-violet-600 text-violet-600 hover:text-white duration-500 ease-in-out mx-auto">
            <i class="mdi mdi-play inline-flex items-center justify-center text-3xl"></i>
          </a>

          <h3 class="font-medium leading-normal text-4xl mb-5 mt-10 text-white">¡Descubre los tesoros
            culturales de Yucatán!
          </h3>

          <p class="text-slate-400 text-lg max-w-xl mx-auto">Explora todos los museos de la región y ubícalos fácilmente aquí, en el directorio de la Red de Museos de Yucatán. Sumérgete en la riqueza histórica y artística de nuestra tierra.
</p>

          <div class="mt-8">
            <a href="/contact"
              class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md"><i
                class="uil uil-envelope"></i> Contáctanos</a>
          </div>
        </div><!--end grid-->
      </div><!--end container-->
    </section><!--end section-->
    <div :class="isActive ? 'fixed' : 'hidden' " class="bg-black/[0.9] top-0 left-0 bottom-0 w-[100%] h-[100%] z-999">
        <div class="h-[100%] flex items-center justify-center">
            <div class="relative inline-block">
                <iframe src="about:blank" ref="videoFrame" width="700" height="500" frameborder="0"></iframe>
                <a class="mdi mdi-close text-white absolute top-1 right-1 text-xl" @click="stopVideo"></a>
            </div>
        </div>
    </div>
    <!-- Hero End -->

    <AboutVue />
    
    <cta />  

    <Blogs />
    
    <GetInTouch />
    
    <Footer />
  </div>
</template>
  
<script>
import NavbarVue from '../components/NavbarComponent.vue';
import AboutVue from "../components/AboutComponent.vue";
import cta from "../components/Cta.vue";
import Blogs from "../components/BlogComponent.vue"
import GetInTouch from '@/components/GetInTouchComponent.vue';
import Footer from '@/components/Footer.vue';
export default {
  name: 'IndexPage',
  data() {
    return {
      isActive: false,
      videoUrl: "/redmy.mp4?autoplay=1"  
    }
  },
  components: {
    NavbarVue,
    AboutVue,
    cta,   
    Blogs,
    GetInTouch,
    Footer,
  },
  async mounted() {   
    this.stopVideo();
   },  
  
   methods: {      
      stopVideo() {
          const iframe = this.$refs.videoFrame;
          iframe.src = 'about:blank';
          this.isActive = false;
      },
      startVideo() {
          this.isActive = true;
          const iframe = this.$refs.videoFrame;
          iframe.src = this.videoUrl;
      },      
   },
}
</script>
  
<style scoped></style>
  