<template>
    <div>
   <!-- Start -->
   <section class="relative md:py-24 py-16 bg-gray-50 dark:bg-slate-800" id="contact">
            <div class="container">
                <div class="grid grid-cols-1 pb-8 text-center">
                    <h3 class="mb-4 md:text-2xl text-xl font-medium">Si tienes alguna duda o sugerencia <br>
                         ¡Contáctanos!
                    </h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-xl mx-auto"></p>
                </div><!--end grid-->

                <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 mt-8 items-center gap-[30px]">
                    <div class="lg:col-span-8">
                        <div class="p-6 rounded-md shadow bg-white dark:bg-slate-900">
                            <form @submit.prevent="submitForm">
                                <div class="grid lg:grid-cols-12 lg:gap-[30px]">
                                    <div class="lg:col-span-6 mb-5">
                                        <input v-model="form.name" required id="name" type="text" class="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Nombre :">
                                    </div>
    
                                    <div class="lg:col-span-6 mb-5">
                                        <input v-model="form.email" required id="email" type="email" class="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Email :">
                                    </div><!--end col-->
                                </div>

                                <div class="grid grid-cols-1">
                                    <div class="mb-5">
                                        <input v-model="form.subject" required id="subject" class="form-input w-full py-2 px-3 h-10 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0" placeholder="Asunto :">
                                    </div>

                                    <div class="mb-5">
                                        <textarea v-model="form.message" required id="comments" class="form-input w-full py-2 px-3 bg-transparent border border-inherit dark:border-gray-800 dark:bg-slate-900 dark:text-slate-200 rounded outline-none focus:border-violet-600/50 dark:focus:border-violet-600/50 focus:ring-0 h-28" placeholder="Mensaje :"></textarea>
                                    </div>
                                </div>
                                <button type="submit" id="submit" name="send" class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md">Enviar Mensaje</button>
                            </form>
                            <div v-if="error" class="error">{{ error }}</div>
                            <div v-if="success" class="success">{{ success }}</div>
                        </div>
                    </div>

                    <div class="lg:col-span-4">
                        <div class="lg:ms-8">
                            <div class="flex">
                                <div class="icons text-center mx-auto">
                                    <i class="uil uil-phone block rounded text-2xl dark:text-white mb-0"></i>
                                </div>
    
                                <div class="flex-1 ms-6">
                                    <h5 class="text-lg dark:text-white mb-2 font-medium">999 341 0430 ext. 1079</h5>
                                    <a href="tel:+152534-468-854" target="_blank" class="text-slate-400">Llámanos de manera inmediata</a>
                                </div>
                            </div>
    
                            <div class="flex mt-4">
                                <div class="icons text-center mx-auto">
                                    <i class="uil uil-envelope block rounded text-2xl dark:text-white mb-0"></i>
                                </div>
    
                                <div class="flex-1 ms-6">
                                    <h5 class="text-lg dark:text-white mb-2 font-medium">info@museosyucatan.com</h5>
                                    <a href="mailto:info@museosyucatan.com" target="_blank" class="text-slate-400">Escríbenos y canalizaremos tus dudas con el equipo correspondiente</a>
                                </div>
                            </div>
    
                            <div class="flex mt-4">
                                <div class="icons text-center mx-auto">
                                    <i class="uil uil-map-marker block rounded text-2xl dark:text-white mb-0"></i>
                                </div>
    
                                <div class="flex-1 ms-6">
                                    <h5 class="text-lg dark:text-white mb-2 font-medium">Estamos en El Gran Museo del Mundo Maya</h5>                                   
                                    <p class="text-slate-400 mb-2"> Calle 60 Norte, Núm. 299 E, Col. Unidad Revolución.<br>
                                    Cordemex, C.P. 97100, Mérida, Yucatán, México.
                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div><!--end grid-->
            </div><!--end container-->
        </section><!--end section-->
        <!-- End -->
    </div>
</template>

<script>
    import { apiClient } from '../services/index';

    export default {
        name: "GetInTouch",
        data() {
            return {
            form: {
                name: '',
                email: '',
                subject: '',
                message: ''
            },
            error: null,
            success: null
            };
        },
        methods: {
            async submitForm() {
                try {
                    this.error = null;
                    this.success = null;
                    const response =  await apiClient.post('/enviar/contacto', this.form); 
                    console.log(response);
                    this.success = 'El correo se envió exitosamente';
                    this.form.name = '';
                    this.form.email = '';
                    this.form.subject = '';
                    this.form.message = '';
                } catch (error) {
                    this.error = 'Ocurrió un error al enviar el correo';
                    }
            }
        }
    }
</script>

<style lang="scss" scoped>
    .error {
    color: red;
    }
    .success {
    color: green;
    }
</style>