 <template>
    <div>
 <!-- Start -->
 <section class="relative md:py-24 py-16" id="about">
    <div class="container mx-auto">
        <div class="grid grid-cols-1 lg:grid-cols-12 md:grid-cols-2 gap-10 items-center">
           
            <div class="lg:col-span-5">
                <div class="lg:ms-5">
                    <h3 class="mb-4 md:text-2xl text-xl font-medium">Somos la Red de Museos de Yucatán ¡Conócenos! </h3>

                    <p class="text-slate-400 dark:text-slate-300 max-w-2xl mx-auto">La Red de Museos de Yucatán (REDMY) es un programa de 
                    colaboración, formación, asesoría, capacitación y difusión para los recintos museísticos del Estado de Yucatán 
                    que tiene la finalidad de generar y desarrollar actividades que mejoren su quehacer como custodios y promotores del 
                    patrimonio cultural y, por ende, su papel como agentes de cambio social.  
                    Esta red reúne a los museos del estado y su adscripción es de carácter gratuito y de forma voluntaria.  </p>
                
                    <div class="relative mt-8">
                        <router-link to="/aboutus" class="py-2 px-5 inline-block font-normal tracking-wide border align-middle transition duration-500 ease-in-out text-base text-center bg-violet-600 hover:bg-violet-700 border-violet-600 hover:border-violet-700 text-white rounded-md">Más<i class="uil uil-arrow-right"></i></router-link>
                    </div>
                </div>
            </div><!--end col-->
             <div class="lg:col-span-7">
                <video width="100%" height="auto" autoplay loop>
                    <source src="../assets/fachada.mp4" type="video/mp4">
                    Your browser does not support the video tag.
                </video>
            </div><!--end col-->
        </div><!--end grid-->
    </div><!--end container-->
</section><!--end section-->
<!-- End -->
    </div>
 </template>
 
 <script>
 import feather from 'feather-icons'

    export default {
        name: "AboutUs",
        data() {
            return {
                
            }
        },
        mounted() {
        feather.replace();
        }
    }
 </script>
 
 <style lang="scss" scoped>
 
 </style>